<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container installment--container">
      <page-loading :show="isLoading" />
      <div class="installment--content">
        <div class="installment--header">
          <div class="img--installment">
            <img
              src="https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/static/cicilsewa-2.png"
              alt="Cicilsewa Image"
              v-if="company === 'cicilsewa'"
            />
            <img
              src="https://rentfix.s3-ap-southeast-1.amazonaws.com/static/img/static/gradana-2.png"
              alt="Gradana Image"
              v-else-if="company === 'gradana'"
            />
            <img
              src="https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/logo.png"
              alt="Rentfix Logo"
              v-else
            />
          </div>
          <div class="header--text">
            <ul v-if="company === 'cicilsewa' && cicilSewa">
              <li>
                {{ `${$t('fintechInstallment.formCicilan.dp')}: ${cicilSewa.dp}` }}
              </li>
              <li>
                {{
                  `${$t('fintechInstallment.formCicilan.rentDuration')}: ${cicilSewa.rent_period}`
                }}
              </li>
              <li
                v-for="(amount, index) in cicilSewa.installment_amount"
                :key="`cicilsewa-amount-${index}`"
              >
                {{ amount }}
              </li>
            </ul>
            <ul v-else-if="company === 'gradana' && gradana">
              <li>
                {{ gradana.dp }}
              </li>
              <li>
                {{ `${$t('fintechInstallment.formCicilan.rentDuration')}: ${gradana.rent_period}` }}
              </li>
              <li
                v-for="(amount, index) in gradana.installment_amount"
                :key="`gradana-amount-${index}`"
              >
                {{ amount }}
              </li>
            </ul>
            <div v-else>
              {{ $t('fintechInstallment.formCicilan.header') }}
            </div>
          </div>
        </div>
        <hr />
        <listing-info :listing="listing" :listingData="listingData" />
        <form class="installment--form" @submit.prevent="postRequest">
          <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
            <div class="left--col">
              <label for="name">
                <span>{{ $t('profile.name') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <input class="basic--input" id="name" type="text" v-model="name" />
              <span class="val-error mt-2" v-if="validation.hasError('name')">{{
                validation.firstError('name')
              }}</span>
            </div>
          </div>
          <div class="form--group" :class="{ 'has-error': validation.hasError('phoneNumber') }">
            <div class="left--col">
              <label for="phone">
                <span>{{ $t('profile.phone') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <cleave
                id="phone"
                v-model="phoneNumber"
                :options="phoneCleaveOption"
                class="basic--input"
                type="text"
                name="phoneNumber"
              />
              <span class="val-error mt-2" v-if="validation.hasError('phoneNumber')">{{
                validation.firstError('phoneNumber')
              }}</span>
            </div>
          </div>
          <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
            <div class="left--col">
              <label for="email">
                <span>{{ $t('loginRegister.email') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <input class="basic--input" id="email" type="email" v-model="email" />
              <span class="val-error mt-2" v-if="validation.hasError('email')">{{
                validation.firstError('email')
              }}</span>
            </div>
          </div>
          <div
            class="form--group"
            :class="{ 'has-error': validation.hasError('occupation') }"
            v-if="company !== 'gradana'"
          >
            <div class="left--col">
              <label for="occupation">
                <span>{{ $t('profile.occupation') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <input class="basic--input" id="occupation" type="text" v-model="occupation" />
              <span class="val-error mt-2" v-if="validation.hasError('occupation')">{{
                validation.firstError('occupation')
              }}</span>
            </div>
          </div>
          <div class="form--group" :class="{ 'has-error': validation.hasError('dateValue') }">
            <div class="left--col">
              <label for="move_in_date">
                <span>{{ $t('general.startFrom') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <client-only>
                <date-picker
                  id="move_in_date"
                  v-model="dateValue"
                  :minDate="new Date()"
                  format="dd/MM/yyyy"
                  class="input--background-white"
                />
              </client-only>
              <span class="val-error mt-2" v-if="validation.hasError('dateValue')">{{
                validation.firstError('dateValue')
              }}</span>
            </div>
          </div>
          <div class="form--group" v-if="company === 'cicilsewa' && cicilanData">
            <div class="left--col">
              <label for="numberofinstallmentCicilSewa">
                <span>{{ $t('fintechInstallment.formCicilan.rentDuration') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <multiselect
                id="numberofinstallmentCicilSewa"
                v-model="selectedInstallment"
                :options="cicilanData"
                :custom-label="customLabelCicilSewa"
                track-by="number_of_installment"
                :allow-empty="false"
              />
            </div>
          </div>
          <div class="form--group" v-else-if="company === 'gradana'">
            <div class="left--col">
              <label for="numberofinstallmentGradana">
                <span>{{ $t('fintechInstallment.formCicilan.installmentQty') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <multiselect
                id="numberofinstallmentGradana"
                v-model="numberOfInstallment"
                :options="cicilanOptionsGradana"
                :custom-label="customLabelGradana"
                :allow-empty="false"
              />
              <div class="installment--qty">
                <span>{{ `${selectedInstallmentGradana} /${$t('date.month.title')}` }}</span> <br />
                <span class="pa-1 mt-1" v-show="numberOfInstallment == 6">{{
                  $t('fintechInstallment.formCicilan.6xMsg')
                }}</span>
                <span class="pa-1 mt-1" v-show="numberOfInstallment == 12">{{
                  $t('fintechInstallment.formCicilan.12xMsg')
                }}</span>
                <!--                <span class="pa-1 mt-1" v-show="numberOfInstallment == 24">{{-->
                <!--                  $t('fintechInstallment.formCicilan.24xMsg')-->
                <!--                }}</span>-->
              </div>
            </div>
          </div>
          <div v-else>
            <div class="form--group" :class="{ 'has-error': validation.hasError('rentPeriod') }">
              <div class="left--col">
                <label>
                  <span>{{ $t('fintechInstallment.formCicilan.rentDuration') }}</span>
                  <span class="required">*</span>
                </label>
              </div>
              <div class="right--col">
                <div class="custom-control custom-radio my-2">
                  <input
                    type="radio"
                    id="rentPeriod1"
                    name="rentPeriod"
                    v-model.number="rentPeriod"
                    value="1"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="rentPeriod1">{{
                    `1 ${$t('general.year')}`
                  }}</label>
                </div>
                <div class="custom-control custom-radio mb-2">
                  <input
                    type="radio"
                    id="rentPeriod2"
                    name="rentPeriod"
                    v-model.number="rentPeriod"
                    value="2"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="rentPeriod2">{{
                    `2 ${$t('general.year')}`
                  }}</label>
                </div>
                <span class="val-error mt-2" v-if="validation.hasError('rentPeriod')">{{
                  validation.firstError('rentPeriod')
                }}</span>
              </div>
            </div>
            <!--            <div-->
            <!--              class="form&#45;&#45;group"-->
            <!--              :class="{ 'has-error': validation.hasError('installmentDuration') }"-->
            <!--            >-->
            <!--              <div class="left&#45;&#45;col">-->
            <!--                <label>-->
            <!--                  <span>{{ $t('fintechInstallment.formCicilan.installmentDuration') }}</span>-->
            <!--                  <span class="required">*</span>-->
            <!--                </label>-->
            <!--              </div>-->
            <!--              <div class="right&#45;&#45;col">-->
            <!--                <div class="custom-control custom-radio my-2">-->
            <!--                  <input-->
            <!--                    type="radio"-->
            <!--                    id="installmentDuration1"-->
            <!--                    name="installmentDuration"-->
            <!--                    v-model.number="installmentDuration"-->
            <!--                    value="1"-->
            <!--                    class="custom-control-input"-->
            <!--                  />-->
            <!--                  <label class="custom-control-label" for="installmentDuration1">{{-->
            <!--                    `1 ${$t('general.year')}`-->
            <!--                  }}</label>-->
            <!--                </div>-->
            <!--                <div class="custom-control custom-radio mb-2" v-show="selectedInstallment === 2">-->
            <!--                  <input-->
            <!--                    type="radio"-->
            <!--                    id="installmentDuration2"-->
            <!--                    name="installmentDuration"-->
            <!--                    v-model.number="installmentDuration"-->
            <!--                    value="2"-->
            <!--                    class="custom-control-input"-->
            <!--                  />-->
            <!--                  <label class="custom-control-label" for="installmentDuration2">{{-->
            <!--                    `2 ${$t('general.year')}`-->
            <!--                  }}</label>-->
            <!--                </div>-->
            <!--                <span class="val-error mt-2" v-if="validation.hasError('installmentDuration')">{{-->
            <!--                  validation.firstError('installmentDuration')-->
            <!--                }}</span>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div v-if="company === 'cicilsewa'">
            <div class="form--group">
              <div class="left--col">
                <label for="name">
                  <span>{{ $t('fintechInstallment.formCicilan.installmentPerMonth') }}</span>
                  <span class="required">*</span>
                </label>
              </div>
              <div class="right--col">
                <div class="input--group">
                  <div class="append--left">Rp</div>
                  <cleave
                    class="basic--input price--input"
                    id="installment_amount_per_month"
                    type="text"
                    :value="
                      selectedInstallment ? selectedInstallment.installment_amount_per_month : ''
                    "
                    :options="currencyCleaveOption"
                    disabled
                  />
                  <div class="append--right">
                    {{ `/ ${$t('date.month.title')}` }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form--group">
              <div class="left--col">
                <label for="name">
                  <span>{{ $t('fintechInstallment.formCicilan.dp') }}</span>
                  <span class="required">*</span>
                </label>
              </div>
              <div class="right--col">
                <div class="input--group">
                  <div class="append--left">Rp</div>
                  <cleave
                    class="basic--input price--input"
                    id="dp"
                    type="text"
                    :value="selectedInstallment ? selectedInstallment.dp : ''"
                    :options="currencyCleaveOption"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="form--group" v-if="company === 'cicilsewa'">
              <div class="left--col">
                <label for="name">
                  <span>{{ $t('fintechInstallment.formCicilan.installmentDuration') }}</span>
                  <span class="required">*</span>
                </label>
              </div>
              <div class="right--col">
                <input
                  class="basic--input"
                  id="installmentDuration"
                  type="text"
                  :value="installmentDuration"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="installment--terms">
            {{ $t('fintechInstallment.formCicilan.tos1') }}
            <a href="">{{ $t('fintechInstallment.formCicilan.tos2') }} </a>
            {{ $t('fintechInstallment.formCicilan.tos3') }}
            <a href=""> {{ $t('fintechInstallment.formCicilan.tos4') }} </a> Rentfix.com.
          </div>
          <div class="d-flex justify-center">
            <button type="submit" class="btn btn-primary">SEND</button>
          </div>
        </form>
      </div>
    </div>
    <tc-modal />
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ListingInfo from '@/components/utils/listing-info';
const PageLoading = () => import('@/components/content-loading/page-loading');
import { mapState } from 'vuex';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { Validator } from 'simple-vue-validator';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
const DatePicker = () => import('@/components/utils/date-picker');
const TcModal = () => import('@/components/installment/modals/tc-modal.vue');

import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
export default {
  name: 'ajukan-cicilan-view',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ListingInfo,
    DatePicker,
    PageLoading,
    TcModal,
    Multiselect,
    Cleave,
  },
  data() {
    return {
      options: {
        format: 'MM/dd/yyyy',
        useCurrent: false,
      },
      phoneCleaveOption: {
        phone: true,
        phoneRegionCode: 'id',
      },
      currencyCleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      cicilanOptionsGradana: [6, 12],
    };
  },
  computed: {
    ...mapState({
      listing: (state) => state.v2.fintechInstallment.listing,
      listingData: (state) => state.v2.fintechInstallment.listingData,
      sixMonthsInstallment: (state) => state.v2.fintechInstallment.sixMonthsInstallment,
      oneYearInstallment: (state) => state.v2.fintechInstallment.oneYearInstallment,
      twoYearInstallment: (state) => state.v2.fintechInstallment.twoYearInstallment,
      cicilanData: (state) => state.v2.fintechInstallment.cicilanData,
      cicilSewa: (state) => state.v2.fintechInstallment.cicilSewa,
      gradana: (state) => state.v2.fintechInstallment.gradana,
    }),
    selectedInstallmentGradana() {
      if (this.numberOfInstallment == 6) {
        return this.$n(this.sixMonthsInstallment, 'currency', 'id-ID');
      } else if (this.numberOfInstallment == 12) {
        return this.$n(this.oneYearInstallment, 'currency', 'id-ID');
      }
      // else if (this.numberOfInstallment == 24) {
      //   return this.$n(this.twoYearInstallment, 'currency', 'id-ID');
      // }
      return 0;
    },
    installmentDuration: {
      get() {
        return this.$store.state.v2.fintechInstallment.installmentDuration;
      },
      set(value) {
        this.$store.commit('v2/fintechInstallment/SET_INSTALLMENT_DURATION', value);
      },
    },
    // installmentDuration() {
    //   if (this.selectedInstallment) {
    //     return `${this.selectedInstallment.number_of_installment} ${this.$t(
    //       'fintechInstallment.formCicilan.timesFor',
    //     )} ${this.selectedInstallment.rent_period_in_year} ${this.$t('general.year')}`;
    //   } else {
    //     return '';
    //   }
    // },
    isLoading: {
      get() {
        return this.$store.state.v2.fintechInstallment.loading;
      },
      set(value) {
        this.$store.commit('v2/fintechInstallment/SET_LOADING', value);
      },
    },
    dateValue: {
      get() {
        return this.$store.state.v2.fintechInstallment.date;
      },
      set(value) {
        this.$store.commit('v2/fintechInstallment/SET_DATE', value);
      },
    },
    name: {
      get() {
        return this.$store.state.v2.fintechInstallment.name;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_NAME', val);
      },
    },
    phoneNumber: {
      get() {
        return this.$store.state.v2.fintechInstallment.phoneNumber;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_PHONE_NUMBER', val);
      },
    },
    email: {
      get() {
        return this.$store.state.v2.fintechInstallment.email;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_EMAIL', val);
      },
    },
    numberOfInstallment: {
      get() {
        return this.$store.state.v2.fintechInstallment.numberOfInstallment;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_NUMBER_OF_INSTALLMENT', val);
      },
    },
    selectedInstallment: {
      get() {
        return this.$store.state.v2.fintechInstallment.selectedInstallment;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_SELECTED_INSTALLMENT', val);
        if (val === 1) {
          this.$store.commit('v2/fintechInstallment/SET_INSTALLMENT_DURATION', 1);
        }
      },
    },
    company: {
      get() {
        return this.$store.state.v2.fintechInstallment.company;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_COMPANY', val);
      },
    },
    occupation: {
      get() {
        return this.$store.state.v2.fintechInstallment.occupation;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_OCCUPATION', val);
      },
    },
    rentPeriod: {
      get() {
        return this.$store.state.v2.fintechInstallment.rentPeriod;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_RENT_PERIOD', val);
      },
    },
    breadcrumbs() {
      if (!this.listing) return [];
      const typeString =
        this.listing.type === 'R' ? this.$t('general.rent') : this.$t('general.sell');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: `${typeString} ${this.listing.property_type_name}`,
          to: `/sewa/search?property_type_id=${this.listing.property_type_id}`,
        },
        {
          text: this.listing.listing_title,
          to: this.listing.web_url,
        },
        {
          text: this.$t('general.installmentRequest'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  validators: {
    name(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(100, this.$i18n.t('errors.name.max', { maxChar: 100 }));
    },
    phoneNumber(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.phone.required'))
        .maxLength(20, this.$i18n.t('errors.phone.max', { maxChar: 20 }));
    },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.invalid'))
        .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
    },
    dateValue(value) {
      return Validator.value(value).required(this.$i18n.t('errors.startDate.required'));
    },
    occupation(value) {
      if (this.company !== 'gradana') {
        return Validator.value(value)
          .required(this.$i18n.t('errors.occupation.required'))
          .maxLength(100, this.$i18n.t('errors.occupation.max', { maxChar: 100 }));
      } else {
        return Validator.value(true);
      }
    },
    rentPeriod(value) {
      return Validator.value(value).required(this.$i18n.t('errors.rentPeriod.required'));
    },
    // installmentDuration(value) {
    //   return Validator.value(value).required(this.$i18n.t('errors.installmentDuration.required'));
    // },
  },
  methods: {
    customLabelCicilSewa(cicilanOption) {
      return `${cicilanOption.rent_period_in_year} ` + this.$t('general.year');
    },
    customLabelGradana(numberOfInstallment) {
      return this.$t('fintechInstallment.formCicilan.installment') + ` ${numberOfInstallment}x`;
    },
    async postRequest() {
      const isValid = await this.$validate();
      console.log('FORM CICILAN IS VALID: ', isValid);
      if (isValid) {
        if (this.company !== 'gradana') {
          try {
            const result = await this.$store.dispatch('v2/fintechInstallment/postRequest');
            // eslint-disable-next-line no-undef
            fbq('track', 'submitInstallment');
            if (result) {
              this.$swal({
                title: 'Sukses!',
                text: 'Pengajuan cicilan telah berhasil dikirim.',
                type: 'success',
                allowOutsideClick: false,
              }).then((response) => {
                if (response.value) {
                  // this.$router.replace('/inbox');
                  this.$router.replace(this.listing.web_url);
                }
              });
            }
            // eslint-disable-next-line no-empty
          } catch (e) {}
        } else {
          this.$modal.show('modal--tc');
        }
      }
    },
  },
  created() {
    this.$store.commit('v2/fintechInstallment/RESTORE_INITIAL_STATE');
    this.company = this.$route.query.company;
    this.$store.commit('v2/fintechInstallment/SET_DATE', new Date());
    this.$store.commit('v2/fintechInstallment/SET_LISTING_UUID', this.$route.query.listingUuid);
    try {
      this.$store.dispatch('v2/fintechInstallment/getData');
      this.$store.dispatch('v2/fintechInstallment/getComparison', {
        listingUuid: this.$route.query.listingUuid,
      });
    } catch (e) {
      console.log('ERROR GET LISTING DATA: ', e);
    }
  },
};
</script>

<style scoped></style>
